import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Chip, Avatar } from '@material-ui/core';
import { ArrowDropDown, Person } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import urlJoin from 'url-join';

import config from 'src/config/local';
import { getCompanyLetters } from 'src/utils/util';


const { serverUrl } = config;

const useStyles = makeStyles(theme => ({
  workIn: {
    lineHeight: '1.2',
  },
  chip: {
    fontWeight: 'bold',
    width: '100%',
  },
  chipLabel: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: 'transparent !important',
    border: `1px solid ${theme.palette.common.gray}`,
  },
}));

const WorkInSection = ({ selectedCompany, actions }) => {
  const classes = useStyles();

  return (
    <Box px={4} py={3} display="flex" flexDirection="column" alignItems="flexStart">
      <Typography variant="caption" className={classes.workIn}>Trabajar en:</Typography>
      <Box display="flex" width="100%">
        <Chip
          variant="outlined"
          classes={{ label: classes.chipLabel }}
          color='primary'
          className={classes.chip}
          avatar={
            <Avatar
              className={clsx({ [classes.avatar]: Boolean(selectedCompany.image) })}
              {...(selectedCompany.image ? { src: urlJoin(serverUrl, selectedCompany.image) } : {})}
            >
              {selectedCompany.private ? <Person /> : getCompanyLetters(selectedCompany.name)}
            </Avatar>
          }
          deleteIcon={<ArrowDropDown />}
          onDelete={() => actions.setOpenDialog(true)}
          label={selectedCompany ? selectedCompany.name : ''}
          onClick={() => actions.setOpenDialog(true)}
        />
      </Box>
    </Box>
  );
};

WorkInSection.propTypes = {
  selectedCompany: PropTypes.shape({
    image: PropTypes.string,
    private: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    setOpenDialog: PropTypes.func.isRequired,
  }).isRequired,
};


export { WorkInSection };
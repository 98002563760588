import React from 'react';
import { Box, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { PendingBillingCard } from 'src/scenes/CompanySettings/scenes/Subscription/components';


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
  billingLinks: {
    borderLeft: `1px solid ${theme.palette.common.gray400}`,
    marginLeft: theme.spacing(2),
  },
}));

const PendingBillingSection = ({ paymentsPreview, currentPlan }) => {
  const classes = useStyles();

  return (
    <Box py={4}>
      <Typography variant="h4" color="error">Pagos pendientes</Typography>
      <Paper elevation={0} className={classes.paper}>
        <Box display="flex">
          <Grid container spacing={1}>
            {paymentsPreview.reverse().map((paymentPreview, idx) =>
              <Grid key={idx} item>
                <PendingBillingCard paymentPreview={paymentPreview} currentPlan={currentPlan} />
              </Grid>,
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

PendingBillingSection.propTypes = {
  paymentsPreview: PropTypes.array.isRequired,
  currentPlan: PropTypes.object.isRequired,
};


export { PendingBillingSection };

import React, { useState } from 'react';
import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

import { DrawerWrapper, WorkInSection, CompanySelectDialog } from 'src/components';
import { companiesApi, usersApi } from 'src/services';


const ExpiredDrawer = ({ company, companies, changeCompany, changeRolePermissions, goToWeb }) => {

  const [ openCompaniesDialog, setOpenDialog ] = useState(false);

  const handleCloseCompaniesDialog = async selectedCompany => {
    setOpenDialog(false);
    if (selectedCompany.id !== company.id) {
      const role = await companiesApi.changeCompany(selectedCompany.id);
      changeCompany({ ...selectedCompany, ...role });
      const rolePermissions = await usersApi.getPermissionsByRole();
      changeRolePermissions(rolePermissions);
      goToWeb();
    }
  };

  return (
    <DrawerWrapper>
      <Box mt={12}>
        <WorkInSection selectedCompany={company} actions={{ setOpenDialog }} />
        <Divider />
      </Box>
      {companies.length > 1 &&
        <CompanySelectDialog
          companies={companies}
          selectedCompany={company}
          actions={{ handleCloseCompaniesDialog }}
          open={openCompaniesDialog}
        />
      }
    </DrawerWrapper>
  );
};

ExpiredDrawer.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  changeCompany: PropTypes.func.isRequired,
  changeRolePermissions: PropTypes.func.isRequired,
  goToWeb: PropTypes.func.isRequired,
};


export { ExpiredDrawer };

import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography, Paper, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Scroll from 'react-scroll';

import { TitleContainer, ContentContainer, DialogWrap } from 'src/components';
import { Payment, Receipt } from '@material-ui/icons';
import { payApi } from 'src/services';
import { useLocation, useHistory } from 'react-router';
import { BillingDataAddDialog, RemoveCreditCardDialog, NextBillingSection, PendingBillingSection, CurrentSubscriptionSection }
  from 'src/scenes/CompanySettings/scenes/Subscription/components';
import { pricing } from 'src/scenes/Home/scenes/Pricing/pricingData';
import checkers from 'src/utils/checkers';
import { truncateWithEllipsis as twe } from 'src/utils/util';
import { upperCaseOnlyFirstLetter as ucofl } from 'src/utils/formatters';


const Element = Scroll.Element;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
  creditCard: {
    width: 'fit-content',
  },
  removeCardBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  icon: {
    fontWeight: 'bold',
  },
  title: {
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  fontWeight500: {
    fontWeight: 500,
  },
}));

const CompanySubscriptionContainer = () => {

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [ creditCard, setCreditCard ] = useState();
  const [ billingData, setBillingData ] = useState();
  const [ subscription, setSubscription ] = useState();
  const [ plans, setPlans ] = useState([]);
  const [ paymentsPreview, setPaymentsPreview ] = useState([]);
  const [ options, setOptions ] = useState({ economicActivities: [] });
  const [ isTrialAccount, setIsTrialAccount ] = useState(true);

  const hasCreditCard = Boolean(creditCard?.lastDigits);
  const hasBillingData = Boolean(billingData?.rut);

  const query = new URLSearchParams(location.search);
  const tbkToken = query.get('TBK_TOKEN');
  const hasTbkToken = Boolean(tbkToken);
  const isAborted = Boolean(query.get('TBK_ORDEN_COMPRA'));

  useEffect(() => {
    const fetch = async () => {
      const creditCard = await payApi.finishCreditCardSubscription(tbkToken);
      setCreditCard(creditCard);
    };

    if (hasTbkToken && !isAborted) {
      fetch();
    }
    history.push({ pathname: location.pathname });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const creditCard = await payApi.getCreditCard();
      setCreditCard(creditCard);
    };
    if (!hasTbkToken) {
      fetch();
    }

    const fetchData = async () => {
      const [ billing, subscription, plans, customPlan, paymentsPreview, economicActivities ] = await Promise.all([
        payApi.getBillingData(),
        payApi.getSubscription(),
        payApi.getCompanyPlans(),
        payApi.getCompanyCustomPlan(),
        payApi.getPendingPayments(),
        payApi.getEconomicActivities(),
      ]);
      const hasAvailableCustomPlan = Boolean(customPlan);
      if (hasAvailableCustomPlan) {
        customPlan.name = 'Personalizado';
        customPlan.description = 'Para empresas con requerimientos específicos';
      }
      setPlans([ ...plans, ...(hasAvailableCustomPlan ? [ customPlan ] : []) ]);
      setSubscription(subscription);
      setBillingData(billing);
      setPaymentsPreview(paymentsPreview);
      setIsTrialAccount(checkers.isTrialAccount(subscription));
      const economicActivitiesOptions = economicActivities.map(({ id, code, name }) => ({ value: id, label: [ code, name ].join(' - ') }));
      setOptions(prevOptions => ({ ...prevOptions, economicActivities: economicActivitiesOptions }));
    };
    fetchData();
  // eslint-disable-next-line
  }, []);

  const handleCreditCardSubscription = async () => {
    const { token, redirectUrl } = await payApi.startCreditCardSubscription();
    goToTransbank(token, redirectUrl);
  };

  const goToTransbank = (token, url) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    const hiddenFieldSample1 = document.createElement('input');
    hiddenFieldSample1.type = 'hidden';
    hiddenFieldSample1.name = 'TBK_TOKEN';
    hiddenFieldSample1.value = token;
    form.appendChild(hiddenFieldSample1);
    document.body.appendChild(form);
    form.submit();
  };

  const [ dialog, setDialog ] = useState({ isOpen: false, type: '' });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openDialog = (type, data) => setDialog({ isOpen: true, type, data });
  const openBillingDataAddDialog = () => openDialog('edit-billing');
  const openCreditCardRemoveDialog = data => openDialog('remove-card', data);

  const getDialogType = () => {
    switch (dialog.type) {
      case 'edit-billing':
        return <BillingDataAddDialog actions={{ closeDialog, addBillingData }} options={options} prevBillingData={billingData} />;
      case 'remove-card':
        return <RemoveCreditCardDialog actions={{ closeDialog, removeCreditCard }} lastDigits={dialog.data} />;
      default:
        break;
    }
  };

  const addBillingData = async newBillingData => {
    const billing = await payApi.addBillingData({ billingDataId: billingData?.id, ...newBillingData });
    setBillingData(prev => ({ ...prev, ...billing }));
  };

  const removeCreditCard = async () => {
    await payApi.removeCreditCard();
    setCreditCard(undefined);
  };

  const goToPlans = () => history.push(`/web/company/subscription/plans`);

  const currentPlan = plans.find(plan => plan.id === subscription?.planId) || {};
  const currentPricing = pricing[subscription?.planId];

  const economicActivityLabel = hasBillingData ?
    [ billingData.economicActivityCode, ucofl(billingData.economicActivityName) ].join(' - ') : '';

  const [ lastPayment, ...pendingPayments ] = paymentsPreview;

  return (
    <Grid container>
      <TitleContainer breadcrumbs={[ 'company', { name: 'Suscripción' } ]}>Suscripción</TitleContainer>
      <ContentContainer maxWidth="lg">
        <Box pb={1}>
          {!isTrialAccount &&
            <>
              {pendingPayments.length > 0 && <PendingBillingSection paymentsPreview={pendingPayments} currentPlan={currentPlan} />}
              {lastPayment && <NextBillingSection paymentPreview={lastPayment} currentPlan={currentPlan} />}
            </>
          }
          <CurrentSubscriptionSection
            isTrialAccount={isTrialAccount}
            goToPlans={goToPlans}
            currentPlan={currentPlan}
            subscription={subscription}
            currentPricing={currentPricing}
          />
          <Box py={4}>
            <Element name="billing">
              <Box display="flex" alignItems="flex-end" pb={0.5}>
                <Box flexGrow={1}>
                  <Typography variant="h4" color="primary">Datos de facturación</Typography>
                </Box>
                <Box>
                </Box>
                {hasBillingData &&
                  <Box ml={1}>
                    <Button color="primary" variant="contained" size="small" startIcon={<Receipt />} onClick={openBillingDataAddDialog}>
                      Editar datos de facturación
                    </Button>
                  </Box>
                }
              </Box>
            </Element>
            <Paper elevation={0} className={classes.paper}>
              {hasBillingData ?
                <Box display="flex" alignItems="center">
                  <Paper>
                    <Box p={4}>
                      <Box display="flex" flexDirection="column">
                        <Typography color="textPrimary" variant="body1">
                          <strong>{billingData.legalName}</strong>
                        </Typography>
                        <Typography className={classes.fontWeight500} color="textPrimary" variant="body1">
                          {billingData.rut}
                        </Typography>
                        <Typography className={classes.fontWeight500} color="textPrimary" variant="body2">
                          <Tooltip title={economicActivityLabel}>
                            <span>
                              {twe(economicActivityLabel, 64)}
                            </span>
                          </Tooltip>
                        </Typography>
                        <br />
                        {billingData.city &&
                          <Typography color="textPrimary" variant="body2">
                            {billingData.city}
                          </Typography>
                        }
                        <Typography color="textPrimary" variant="body2">
                          {billingData.commune}
                        </Typography>
                        <Typography color="textPrimary" variant="body2">
                          {billingData.address}
                        </Typography>
                        <br />
                        <Typography color="textPrimary" variant="body2">
                          {billingData.contact}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                : <Box display="flex" alignItems="center">
                  <Typography variant="body1">No se ha agregado datos de facturación</Typography>
                  <Box flexGrow={1} />
                  <Button variant="outlined" onClick={openBillingDataAddDialog} color="primary" startIcon={<Receipt />}>
                    Agregar datos de facturación
                  </Button>
                </Box>
              }
            </Paper>
          </Box>
          <Box py={4}>
            <Element name="card">
              <Box display="flex" alignItems="flex-end" pb={0.5}>
                <Box flexGrow={1}>
                  <Typography variant="h4" color="primary">Medio de pago</Typography>
                </Box>
                <Box>
                </Box>
                {hasCreditCard &&
                  <Box ml={1}>
                    <Button
                      className={classes.removeCardBtn}
                      startIcon={<Payment />}
                      variant="contained"
                      size="small"
                      onClick={() => openCreditCardRemoveDialog(creditCard.lastDigits)}
                    >
                      Eliminar medio de pago
                    </Button>
                  </Box>
                }
              </Box>
            </Element>
            <Paper elevation={0} className={classes.paper}>
              {hasCreditCard ?
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <Paper className={classes.creditCard}>
                      <Box display="flex" p={2}>
                        <Payment />
                        <Box ml={1}>
                          <Typography variant='body1'>
                            {creditCard.type} {creditCard.lastDigits}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                </Box>
                :
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">No se ha asociado un medio de pago</Typography>
                  <Box flexGrow={1} />
                  <Button variant="outlined" onClick={handleCreditCardSubscription} startIcon={<Payment />} color="primary">
                    Agregar medio de pago
                  </Button>
                </Box>
              }
            </Paper>
          </Box>
        </Box>
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title' open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};


export { CompanySubscriptionContainer };
import React from 'react';
import { Grid, Paper, Box, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import { ContactMail } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  plan: {
    textAlign: 'center',
  },
  planName: {
    textTransform: 'uppercase',
  },
  users: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    color: theme.palette.common.gray600,
  },
  price: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  priceExtra: {
    color: theme.palette.common.gray600,
  },
  paper: {
    height: '100%',
  },
  billed: {
    color: theme.palette.common.black,
    fontWeight: '500',
    marginBottom: theme.spacing(1),
  },
  contactText: {
    fontWeight: '500',
  },
}));

const CustomSubscriptionPlan = ({ plan }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid item xs={3} className={classes.plan}>
      <Paper elevation={0} className={classes.paper}>
        <Box height="100%" py={2} display="flex" flexDirection="column">
          <Typography className={classes.planName} color="textPrimary" variant="h5">{plan.name}</Typography>
          <Typography className={classes.users} color="textSecondary" variant="caption">
            La cantidad de usuarios que necesites
          </Typography>
          <Box mx={2} display="flex" alignItems="center" flexGrow={1}>
            <Typography className={classes.contactText} color="textPrimary" variant="body2">
              Contáctanos y te ayudaremos a encontrar la mejor solución para tu negocio.
            </Typography>
          </Box>
          <Box>
            <Button
              size="small"
              variant="contained"
              startIcon={<ContactMail />}
              color="primary"
              onClick={() => history.push('/contact')}
            >
              Contáctanos
            </Button>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

CustomSubscriptionPlan.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};


export { CustomSubscriptionPlan };
import React from 'react';
import { Box, Typography, Button, Paper, Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Scroll from 'react-scroll';
import PropTypes from 'prop-types';

import { LabelChip } from 'src/components';
import { CheckIcon, CloseIcon } from 'src/utils/mdIcons';
import { formatDate } from 'src/utils/util';


const Element = Scroll.Element;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
  title: {
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  icon: {
    fontWeight: 'bold',
  },
  description: {
    color: theme.palette.common.gray600,
  },
  closeIcon: {
    color: theme.palette.error.main,
  },
  notIncluded: {
    height: '100%',
  },
}));

const CurrentSubscriptionSection = ({ isTrialAccount, goToPlans, currentPlan, subscription, currentPricing }) => {
  const classes = useStyles();

  return (
    <Box py={4}>
      <Element name="current">
        <Box display="flex" alignItems="flex-end" pb={0.5}>
          <Box flexGrow={1}>
            <Typography variant="h4" color="primary">Suscripción actual</Typography>
          </Box>
          <Box>
            <Button color="primary" variant="outlined" size="small" onClick={goToPlans}>Comparar planes</Button>
          </Box>
          <Box ml={1}>
            <Button color="primary" variant="contained" size="small" onClick={goToPlans}>Actualizar plan</Button>
          </Box>
        </Box>
      </Element>
      <Paper elevation={0} className={classes.paper}>
        {(isTrialAccount && subscription) &&
          <Grid container spacing={2}>
            <Grid item>
              <Paper>
                <Box display="flex" p={4} flexDirection="column">
                  <Typography color="textPrimary" variant="h6" className={classes.title}>
                    Plan de prueba
                  </Typography>
                  <Typography color="textSecondary" variant="body2" className={classes.description}>
                    Para empresas que están probando la plataforma
                  </Typography>
                  <Box mt={1} display="flex" alignContent="center">
                    <Box>
                      <LabelChip color="default" variant="outlined" label={`Termina el ${formatDate(subscription?.endDate)}`} />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        }
        {(!isTrialAccount && currentPlan) &&
          <Grid container spacing={2}>
            <Grid item>
              <Paper>
                <Box display="flex" p={4} flexDirection="column">
                  <Typography color="textPrimary" variant="h6" className={classes.title}>
                    Plan {currentPlan?.name}
                  </Typography>
                  <Typography color="textSecondary" variant="body2" className={classes.description}>
                    {currentPlan?.description}
                  </Typography>
                  <Box mt={1} display="flex" alignContent="center">
                    <Box mr={1}>
                      <LabelChip color="default" variant="outlined" label={`Modalidad ${subscription?.billingFrequencyName}`} />
                    </Box>
                    <Box>
                      <LabelChip color="default" variant="outlined" label={`Termina el ${formatDate(subscription?.endDate)}`} />
                    </Box>
                  </Box>
                  {currentPricing && <Box py={2}><Divider /></Box>}
                  {currentPricing?.features.data.map((feat, index) =>
                    <Box key={index} display="flex" py={1} width="100%" alignItems="center">
                      <Box pr={2} display="flex" alignItems="center">
                        <CheckIcon className={classes.icon} fontSize="small" color="primary" />
                      </Box>
                      <Box flexGrow={1}>
                        <Typography color="textPrimary" variant="body2">{feat}</Typography>
                      </Box>
                    </Box>,
                  )}
                </Box>
              </Paper>
            </Grid>
            {currentPricing?.notFeatures.data.length > 0 &&
              <Grid item>
                <Paper className={classes.notIncluded}>
                  <Box display="flex" p={4} flexDirection="column">
                    <Typography color="textPrimary" variant="h6">No incluye</Typography>
                    <br />
                    {currentPricing.notFeatures.data.map((feat, index) =>
                      <Box key={index} display="flex" py={1} width="100%" alignItems="center">
                        <Box pr={2} display="flex" alignItems="center">
                          <CloseIcon className={classes.closeIcon} fontSize="small" />
                        </Box>
                        <Box flexGrow={1}>
                          <Typography color="textPrimary" variant="body2">{feat}</Typography>
                        </Box>
                      </Box>,
                    )}
                  </Box>
                </Paper>
              </Grid>
            }
          </Grid>
        }
      </Paper>
    </Box>
  );
};

CurrentSubscriptionSection.propTypes = {
  goToPlans: PropTypes.func.isRequired,
  isTrialAccount: PropTypes.bool.isRequired,
  currentPlan: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  subscription: PropTypes.shape({
    billingFrequencyName: PropTypes.string,
    endDate: PropTypes.string,
  }),
  currentPricing: PropTypes.shape({
    features: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.string),
    }),
    notFeatures: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};


export { CurrentSubscriptionSection };
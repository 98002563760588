import React from 'react';
import { Box, Typography, Paper, Link } from '@material-ui/core';
import * as Scroll from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { BillingCard } from 'src/scenes/CompanySettings/scenes/Subscription/components';


const scroller = Scroll.scroller;
const headerHight = 97;
const scrollConfig = { duration: 500, smooth: true, offset: -1 * headerHight };

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
  billingLinks: {
    borderLeft: `1px solid ${theme.palette.common.gray400}`,
    marginLeft: theme.spacing(2),
  },
}));

const NextBillingSection = ({ paymentPreview, currentPlan }) => {
  const classes = useStyles();

  return (
    <Box py={4}>
      <Typography variant="h4" color="primary">Facturación actual</Typography>
      <Paper elevation={0} className={classes.paper}>
        <Box display="flex">
          <Box width="70%">
            <BillingCard paymentPreview={paymentPreview} currentPlan={currentPlan} />
          </Box>
          <Box p={2} width="30%" className={classes.billingLinks} display="flex" flexDirection="column">
            <Link variant="body2" onClick={() => scroller.scrollTo('current', scrollConfig)} >
              Suscripción actual
            </Link>
            <Link variant="body2" onClick={() => scroller.scrollTo('billing', scrollConfig)}>
              Datos de facturación
            </Link>
            <Link variant="body2" onClick={() => scroller.scrollTo('card', scrollConfig)}>
              Medio de pago
            </Link>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

NextBillingSection.propTypes = {
  paymentPreview: PropTypes.object.isRequired,
  currentPlan: PropTypes.object.isRequired,
};


export { NextBillingSection };

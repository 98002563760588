import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-easybutton';
import config from 'src/config/local';
import ReactDOMServer from 'react-dom/server';
import { ThemeProvider } from '@material-ui/core';

import themes from 'src/themes';
import { MonitoringMapLegend, PointPopup, IndirectRecordPopup, IndividualRecordPopup, IsolatedRecordPopup,
  IndividualPopup, MethodologyPopup } from 'src/scenes/Campaign/scenes/Monitoring/components';
import { getMarkerIcon, addPixiLayerOnLeafletLayer } from 'src/components/map/mapHelper';
import checkers from 'src/utils/checkers';


const { satelliteStreetsBaseMap } = config;

const render = Component =>
  ReactDOMServer.renderToString(
    <ThemeProvider theme={themes['nviro']}>
      <Component />
    </ThemeProvider>,
  );

const pointStatusMapper = {
  todo: { color: 'red', hex: '#f44336', text: 'Pendiente' },
  synced: { color: 'nviro', hex: '#5193eb', text: 'Sincronizada' },
};


const MonitoringMap = props => {

  const mapRef = useRef();

  const leafletLayers = {
    pointsItems: new L.FeatureGroup(),
    riItems: new L.FeatureGroup(),
    censusItems: new L.FeatureGroup(),
    arItems: new L.FeatureGroup(),
    indirectRecordItems: new L.FeatureGroup(),
    faunaIndividuals: new L.FeatureGroup(),
    methodologiesItems: new L.FeatureGroup(),
  };

  const [ pixiContainers, setPixiContainers ] = useState({
    pointsLayer: null,
    risLayer: null,
    censusesLayer: null,
    isolatedsLayer: null,
    individualsLayer: null,
    indirectsLayer: null,
  });

  const campaignTrack = new L.FeatureGroup();
  const interestAreaLeaf = new L.FeatureGroup();

  const [ layerControl, setLayerControl ] = useState();

  const { pointsItems, riItems, censusItems, arItems, indirectRecordItems, faunaIndividuals, methodologiesItems } = leafletLayers;
  const { pointsLayer, risLayer, censusesLayer, isolatedsLayer, individualsLayer, indirectsLayer } = pixiContainers;

  useEffect(() => {
    mapRef.current = L.map('monitoring-map', {
      center: [ -33.4679997, -70.7003513 ],
      zoom: 11,
      layers: [
        L.tileLayer(satelliteStreetsBaseMap, {
          maxZoom: 22,
          maxNativeZoom: 22,
        }),
      ],
    });

    const map = mapRef.current;

    map.createPane('interestAreaPane').style.zIndex = 200;
    map.createPane('trackLinePane').style.zIndex = 300;

    map.addLayer(pointsItems);
    map.addLayer(riItems);
    map.addLayer(censusItems);
    map.addLayer(arItems);
    map.addLayer(indirectRecordItems);
    map.addLayer(faunaIndividuals);

    map.addLayer(campaignTrack);
    map.addLayer(interestAreaLeaf);

    map.addLayer(methodologiesItems);

    const containers = {};
    const buildPixiLayers = async () => {
      await Promise.all([
        { id: 'pointsLayer', leafletLayer: pointsItems },
        { id: 'risLayer', leafletLayer: riItems },
        { id: 'censusesLayer', leafletLayer: censusItems },
        { id: 'isolatedsLayer', leafletLayer: arItems },
        { id: 'individualsLayer', leafletLayer: faunaIndividuals },
        { id: 'indirectsLayer', leafletLayer: indirectRecordItems },
      ].map(({ id, leafletLayer }) => {
        const actions = id === 'censusesLayer' ? { setPopoverData: props.setPopoverData } : { getPopup: data => getPopup(id, data) };
        return addPixiLayerOnLeafletLayer({ id, leafletLayer, actions });
      }));

      map.eachLayer(({ _pixiContainer }) => {
        if (_pixiContainer) {
          const id = _pixiContainer.metaData.id;
          if ([ 'pointsLayer', 'risLayer', 'censusesLayer', 'isolatedsLayer', 'individualsLayer', 'indirectsLayer' ].includes(id)) {
            containers[id] = _pixiContainer;
          }
        }
      });

      setPixiContainers(containers);

      fitMapBounds();
      addLayerControl();
      addLegend();
    };

    buildPixiLayers();

    return () => {
      Object.values(containers).forEach(pixiContainer => pixiContainer.metaData.cleanRenderer());
      mapRef.current.remove();
    };
    // eslint-disable-next-line
  }, []);

  const createMarkersFromPoints = pointsList => {
    const pointsToAdd = pointsList.map(point => ({
      ...point,
      color: (point.synced === false || point.statusId === 'todo') ? '#ff0017' : '#5193eb',
      isSelected: false,
    }));
    pointsLayer && pointsLayer.metaData.createMarkers(pointsToAdd);
  };

  const createMarkersFromIndividualRecords = irs => {
    const risToAdd = irs.map(ir => ({
      ...ir,
      name: 'RI',
      coords: { lat: ir.latitude, lng: ir.longitude },
      color: '#8BC63D',
      isSelected: false,
    }));
    risLayer && risLayer.metaData.createMarkers(risToAdd);
  };

  const createMarkersFromCensusRecord = records => {
    const recordsToAdd = records.map(record => ({
      ...record,
      coords: { lat: record.latitude, lng: record.longitude },
      name: 'RC',
      color: '#8BC63D',
      isSelected: false,
    }));
    censusesLayer && censusesLayer.metaData.createMarkers(recordsToAdd);
  };

  const createMarkersFromIsolatedRecords = records => {
    const recordsToAdd = records.map(record => ({
      ...record,
      name: 'RA',
      coords: { lat: record.latitude, lng: record.longitude },
      color: '#FBB03B',
      isSelected: false,
    }));
    isolatedsLayer && isolatedsLayer.metaData.createMarkers(recordsToAdd);
  };

  const createMarkersFromIndirectsRecord = records => {
    const recordsToAdd = records.map(record => ({
      ...record,
      name: 'RI',
      coords: { lat: record.latitude, lng: record.longitude },
      color: '#c10091',
      isSelected: false,
    }));
    indirectsLayer && indirectsLayer.metaData.createMarkers(recordsToAdd);
  };

  const createMarkersFromDirectRecord = individuals => {
    const recordsToAdd = individuals.map(individual => ({
      ...individual,
      name: 'I',
      coords: { lat: individual.latitude, lng: individual.longitude },
      color: '#fabb00',
      isSelected: false,
    }));
    individualsLayer && individualsLayer.metaData.createMarkers(recordsToAdd);
  };

  const getPopup = (id, data) => {
    const mapper = {
      pointsLayer: () => <PointPopup
        point={data}
        pointStatusMapper={pointStatusMapper}
        csLang={props.csLang}
        campaignSubtypeId={props.campaignSubtypeId}
        campaignHash={props.campaignHash}
      />,
      risLayer: () => <IndividualRecordPopup individualRecord={data} />,
      isolatedsLayer: () => <IsolatedRecordPopup isolatedRecord={data} options={props.options} />,
      indirectsLayer: () => <IndirectRecordPopup indirectRecord={data} options={props.options} />,
      individualsLayer: () =>
        <IndividualPopup individual={{ ...data, email: data.email, scientificName: data.scientificName }} options={props.options} />,
    };
    const popupElement = render(mapper[id]);
    return popupElement;
  };

  const createGeomsFromMethodology = methodology => {
    const coords = { lat: methodology.latitude, lng: methodology.longitude };
    const { typeId } = methodology;

    const methodologyMap = {
      'lineal-transect-amphibians': {
        label: 'TLAn',
        color: 'grey',
        createLayer: () => L.geoJSON(methodology.track),
      },
      'lineal-transect-birds': {
        label: 'TLAv',
        color: 'grey',
        createLayer: () => L.geoJSON(methodology.track),
      },
      'lineal-transect-mammals': {
        label: 'TLM',
        color: 'grey',
        createLayer: () => L.geoJSON(methodology.track),
      },
      'lineal-transect-reptiles': {
        label: 'TLR',
        color: 'grey',
        createLayer: () => L.geoJSON(methodology.track),
      },
      'lineal-transect-mixed': {
        label: 'TL',
        color: 'grey',
        createLayer: () => L.geoJSON(methodology.track),
      },
      'sampling-point': {
        label: 'PM',
        color: 'calypso',
        createLayer: () => L.circle(coords, { color: 'red', fillColor: '#f03', fillOpacity: 0.5, radius: methodology.radius }),
      },
      'sherman-traps': {
        label: 'TS',
        color: 'black',
        createLayer: () => {
          const area = L.circle(coords, { color: 'red', fillColor: '#f03', fillOpacity: 0.5, radius: methodology.area });
          const equipments = methodology.equipments.map(t => {
            const coords = { lat: t.lat, lng: t.lng };
            return new L.Marker(coords, { icon: getMarkerIcon(`TS${t.name}`, 'black') });
          });
          return L.layerGroup(equipments).addLayer(area);
        },
      },
      'aerial-transit': {
        label: 'TA',
        color: 'purple',
      },
      'camera-traps': {
        label: 'TC',
        color: 'blue',
        createLayer: () => {
          const area = L.circle(coords, { color: 'red', fillColor: '#f03', fillOpacity: 0.5, radius: 5 });
          const equipments = methodology.equipments.map(t => {
            const coords = { lat: t.lat, lng: t.lng };
            return new L.Marker(coords, { icon: getMarkerIcon(`CT${t.name}`, 'black') });
          });
          return L.layerGroup(equipments).addLayer(area);
        },
      },
      'echo-location-detection': {
        label: 'DEL',
        color: 'orange',
        createLayer: () => {
          const area = L.circle(coords, { color: 'red', fillColor: '#f03', fillOpacity: 0.5, radius: 5 });
          const equipments = methodology.equipments.map(t => {
            const coords = { lat: t.lat, lng: t.lng };
            return new L.Marker(coords, { icon: getMarkerIcon(`G${t.name}`, 'black') });
          });
          return L.layerGroup(equipments).addLayer(area);
        },
      },
      'play-back-amphibians': {
        label: 'PBAn',
        color: 'yellow',
      },
      'play-back-birds': {
        label: 'PBAv',
        color: 'yellow',
      },
    };

    const marker = new L.Marker(coords, { icon: getMarkerIcon(methodologyMap[typeId].label, methodologyMap[typeId].color) });
    marker.options.isSelected = false;

    const methodologyPopup = render(() =>
      <MethodologyPopup campaignHash={props.campaignHash} methodology={methodology} options={props.options} />);
    marker.bindPopup(methodologyPopup);

    if (methodologyMap[typeId].createLayer) {
      const layer = methodologyMap[typeId].createLayer();
      marker.getPopup().on('add', () => methodologiesItems.addLayer(layer));
      marker.getPopup().on('remove', () => methodologiesItems.removeLayer(layer));
    }

    methodologiesItems.addLayer(marker);

  };

  const createLineFromCampaignTrack = campaignTracksList => {

    campaignTrack?.clearLayers();
    campaignTracksList
      .filter(ct => ct.trackLine)
      .forEach(({ trackLine, ...ctrack }) => {
        const color = `#${Math.random().toString(16).substr(-6)}`;
        const line = L.geoJSON(trackLine, { style: { color }, pane: 'trackLinePane' }).addTo(campaignTrack);
        line.data = { color, ...ctrack };
      });
  };

  const createLayerFromGeoJson = () => {
    interestAreaLeaf?.clearLayers();
    props.interestArea.forEach(layer => {
      const leafletLayer = L.geoJSON(layer.geojson, {
        id: layer.id,
        name: layer.name,
        pane: 'interestAreaPane',
        pointToLayer: (feature, latlng) => new L.CircleMarker(latlng, { pane: 'interestAreaPane', radius: 5, fillOpacity: 1 }),
      });
      interestAreaLeaf.addLayer(leafletLayer);
    });
  };

  const { pointsList, campaignTracksList, interestArea, campaignSubtypeId, individualRecordsList, isolatedRecordsList,
    indirectRecordsList, individualsList } = props;

  createMarkersFromPoints(pointsList);
  createLineFromCampaignTrack(campaignTracksList);
  createLayerFromGeoJson(interestArea);

  if (checkers.isPlot(campaignSubtypeId)) {
    createMarkersFromIndividualRecords(individualRecordsList);
  } else if (checkers.isCensus(campaignSubtypeId)) {
    createMarkersFromCensusRecord(props.censusRecordsList);
  } else if (checkers.isStation(campaignSubtypeId)) {
    createMarkersFromIsolatedRecords(isolatedRecordsList);
    createMarkersFromDirectRecord(individualsList);
    createMarkersFromIndirectsRecord(indirectRecordsList);
    props.methodologiesList.forEach(m => createGeomsFromMethodology(m));
  }

  const _hasLayers = array => array && array.length > 0;

  let legend;
  const addLegend = () => {

    if (campaignTrack) {
      legend && legend?.remove();
      legend = L.control({ position: 'bottomleft' });
      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info bcw-legend');
        const legendElement = render(() =>
          <MonitoringMapLegend
            trackLayers={campaignTrack.getLayers()}
            pointStatusMapper={pointStatusMapper}
            interestArea={props.interestArea}
            csLang={props.csLang}
            hasIsolatedRecords={_hasLayers(props.isolatedRecordsList)}
            hasCensusRecords={_hasLayers(props.censusRecordsList)}
            hasIndividualRecords={_hasLayers(props.individualRecordsList)}
            hasIndirectRecords={_hasLayers(props.indirectRecordsList)}
            hasFaunaIndividuals={_hasLayers(props.individualsList)}
            hasLinealTransect={props.methodologiesList.some(m => m.typeId.includes('lineal-transect-'))}
            hasSamplingPoint={props.methodologiesList.some(m => m.typeId === 'sampling-point')}
            hasShermanTrap={props.methodologiesList.some(m => m.typeId === 'sherman-traps')}
            hasAerialTransit={props.methodologiesList.some(m => m.typeId === 'aerial-transit')}
            hasCameraTrap={props.methodologiesList.some(m => m.typeId === 'camera-traps')}
            hasEchoLocationDetection={props.methodologiesList.some(m => m.typeId === 'echo-location-detection')}
            hasPlayBack={props.methodologiesList.some(m => m.typeId.includes('play-back-'))}
          />);
        div.innerHTML = legendElement;
        return div;
      };
      legend.addTo(mapRef.current);
    }
  };

  const _writeLayerName = (baseName, dataListLen, limit, originalLength) => dataListLen < originalLength ?
    // eslint-disable-next-line max-len
    `<span title="Para no sobrecargar el mapa y alentar al navegador, cada capa muestra un máximo de ${limit} elementos.">
      ${baseName} (mostrando ${limit} de ${originalLength} ⚠️)
    </span>
  ` : baseName;

  const addLayerControl = () => {
    const { csLang, pointsList, campaignTracksList, interestArea, itemsLengthLimit, originalLengths,
      censusRecordsList, individualRecordsList, methodologiesList, indirectRecordsList, isolatedRecordsList, individualsList } = props;

    const layersControl = {
      ...(_hasLayers(pointsList) ?
        { [_writeLayerName(csLang.Points, pointsList.length, itemsLengthLimit, originalLengths.pointsList)]: pointsItems }
        : {}
      ),
      ...(_hasLayers(campaignTracksList) ? { 'Track de campaña': campaignTrack } : {}),
      ...(_hasLayers(interestArea) ? { 'Área de interés': interestAreaLeaf } : {}),

      ...(_hasLayers(individualRecordsList) ?
        { [_writeLayerName(
          'Registros individuales', individualRecordsList.length, itemsLengthLimit, originalLengths.individualRecordsList,
        )]: riItems } : {}
      ),
      ...(_hasLayers(censusRecordsList) ?
        { [_writeLayerName('Registros', censusRecordsList.length, itemsLengthLimit, originalLengths.censusRecordsList)]: censusItems } : {}
      ),
      ...(_hasLayers(isolatedRecordsList) ?
        { [_writeLayerName(
          'Registros aislados', isolatedRecordsList.length, itemsLengthLimit, originalLengths.isolatedRecordsList,
        )]: arItems } : {}
      ),
      ...(_hasLayers(indirectRecordsList) ?
        { [_writeLayerName(
          'Registros indirectos', indirectRecordsList.length, itemsLengthLimit, originalLengths.indirectRecordsList,
        )]: indirectRecordItems } : {}
      ),
      ...(_hasLayers(individualsList) ?
        { [_writeLayerName(
          'Individuos', individualsList.length, itemsLengthLimit, originalLengths.individualsList,
        )]: faunaIndividuals } : {}
      ),
      ...(_hasLayers(methodologiesList) ?
        { [_writeLayerName(
          'Metodologías', methodologiesList.length, itemsLengthLimit, originalLengths.methodologiesList,
        )]: methodologiesItems } : {}
      ),
    };

    layerControl && layerControl?.remove();

    const aer = L.control.layers({}, layersControl, { collapsed: false });
    aer.addTo(mapRef.current);
    setLayerControl(aer);
  };

  const fitMapBounds = () => {
    let bounds;
    const { pointsList, interestArea, individualRecordsList, censusRecordsList, isolatedRecordsList,
      indirectRecordsList, individualsList, methodologiesList } = props;

    const stuffLists = [
      pointsList, individualRecordsList, censusRecordsList, isolatedRecordsList,
      indirectRecordsList, individualsList, methodologiesList,
    ];

    stuffLists.forEach(list => {
      if (list?.length) {
        const latLngs = list.map(p => p.coords ?? [ p.latitude, p.longitude ]);
        const stuffBounds = new L.LatLngBounds(latLngs);
        bounds ? bounds.extend(stuffBounds) : bounds = stuffBounds;
      }
    });

    if (interestArea.length) {
      const interestAreaBounds = interestAreaLeaf.getBounds();
      bounds ? bounds.extend(interestAreaBounds) : bounds = interestAreaBounds;
    }

    bounds && mapRef.current.fitBounds(bounds);
  };

  return <>
    <div id="monitoring-map" style={{ width: '100%', height: '75vh' }}></div>
  </>;
};

MonitoringMap.propTypes = {
  pointsList: PropTypes.array.isRequired,
  campaignTracksList: PropTypes.array.isRequired,
  interestArea: PropTypes.array.isRequired,
  csLang: PropTypes.object,
  campaignSubtypeId: PropTypes.string,
  campaignHash: PropTypes.string,
  //según la campaña
  individualRecordsList: PropTypes.array,

  censusRecordsList: PropTypes.array,

  isolatedRecordsList: PropTypes.array,
  indirectRecordsList: PropTypes.array,
  individualsList: PropTypes.array,
  methodologiesList: PropTypes.array,
  itemsLengthLimit: PropTypes.number,
  originalLengths: PropTypes.object,
  options: PropTypes.object,
  setPopoverData: PropTypes.func,
};


export { MonitoringMap };

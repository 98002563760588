import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import urlJoin from 'url-join';
import { makeStyles } from '@material-ui/core/styles';

import { getCompanyLetters } from 'src/utils/util';
import config from 'src/config/local';


const { serverUrl } = config;

const useStyles = makeStyles(theme => ({
  avatar: {
    border: `1px solid ${theme.palette.common.gray}`,
  },
}));

const CompanySelectDialog = props => {
  const { companies, selectedCompany, open, actions } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={() => actions.handleCloseCompaniesDialog(selectedCompany)} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Trabajar en:</DialogTitle>
      <List>
        {companies.map(company => (
          <ListItem
            selected={company.id === selectedCompany.id}
            button
            onClick={() => actions.handleCloseCompaniesDialog(company)} key={company.id}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar} {...(company.image ? { src: urlJoin(serverUrl, company.image) } : {})}>
                {company.private ? <Person /> : getCompanyLetters(company.name)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={company.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

CompanySelectDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  selectedCompany: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
};


export {
  CompanySelectDialog,
};
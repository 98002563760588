import React, { useContext, useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Divider, ListSubheader, ListItemIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { People, Work, Eco, Business } from '@material-ui/icons';

import { companiesApi, usersApi } from 'src/services';
import { WebContext } from 'src/scenes/web-context';
import { CompanySelectDialog } from 'src/components/header/CompanySelectDialog';
import { useHistory, useRouteMatch } from 'react-router';
import { AccessControl, DrawerWrapper, WorkInSection } from 'src/components';


const useStyles = makeStyles(theme => ({
  listItemSelected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.common.white,
  },
  longText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const paths = [ '/web/company/users', '/web/company/subscription', '/web/projects', '/web/company/environment' ];

const CompanyDrawer = ({ shouldUpdate = {}, stopUpdate = () => false }) => {
  const classes = useStyles();
  const context = useContext(WebContext);
  const { selectedCompany, hasPermissionFor, changeCurrentPath } = context;

  const [ fetchData, setFetchData ] = useState({ companies: [], lastProjectsViewed: [], lastCampaignsViewed: [] });

  const [ openCompaniesDialog, setOpenDialog ] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const { path } = match;
  const [ selectedIndex, setSelectedIndex ] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const [ companies, lastProjectsViewed, lastCampaignsViewed ] = await Promise.all([
        companiesApi.getSelectableCompanies(),
        usersApi.getLastProjectsViewed(),
        hasPermissionFor('campaigns-page:visit') ? usersApi.getLastCampaignsViewed() : [],
      ]);
      setFetchData({ companies, lastProjectsViewed, lastCampaignsViewed });
    };
    fetchData();
    changeCurrentPath(path);
    setSelectedIndex(paths.findIndex(p => p === path));
  }, [ hasPermissionFor, path, changeCurrentPath ]);

  useEffect(() => {
    const fetchLastProjectsViewed = async () => {
      const lastProjectsViewed = await usersApi.getLastProjectsViewed();
      setFetchData(fd => ({ ...fd, lastProjectsViewed }));
    };
    const fetchLastCampaignViewed = async () => {
      const lastCampaignsViewed = hasPermissionFor('campaigns-page:visit') ? await usersApi.getLastCampaignsViewed() : [];
      setFetchData(fd => ({ ...fd, lastCampaignsViewed }));
    };
    if (shouldUpdate.latestProjects) {
      fetchLastProjectsViewed();
      stopUpdate();
    }
    if (shouldUpdate.latestCampaigns) {
      fetchLastCampaignViewed();
      stopUpdate();
    }
  });

  const handleSelectedIndex = index => () => {
    history.push(paths[index]);
    setSelectedIndex(index);
    context.changeCurrentPath(match.path);
  };

  const handleCloseCompaniesDialog = async selectedCompany => {
    setOpenDialog(false);
    if (selectedCompany.id !== context.selectedCompany.id) {
      const role = await companiesApi.changeCompany(selectedCompany.id);
      context.changeCompany({ ...selectedCompany, ...role });
      const rolePermissions = await usersApi.getPermissionsByRole();
      context.changeRolePermissions(rolePermissions);
      history.push(`/web`);
    }
  };

  const goToCampaign = lcv => history.push(`/web/campaigns/${lcv.campaignHash}${lcv.status === 'pending' ? '/planning' : ''}`);

  const goToProject = lcp => history.push(`/web/projects/${lcp.projectId}/campaigns`);

  const isPersonalAccount = Boolean(context.selectedCompany.private);

  const { companies, lastProjectsViewed, lastCampaignsViewed } = fetchData;

  return (
    <DrawerWrapper>
      <Box mt={12}>
        <WorkInSection selectedCompany={selectedCompany} actions={{ setOpenDialog }} />
        <Divider />
        <List>
          <AccessControl action="company-page:visit">
            <ListSubheader color="primary">Administrar empresa</ListSubheader>
            <ListItem selected={selectedIndex === 2} classes={{ selected: classes.listItemSelected }}
              button component="a" onClick={handleSelectedIndex(2)}>
              <ListItemIcon><Work /></ListItemIcon><ListItemText primary={'Proyectos'} />
            </ListItem>
            {context.selectedCompany.role !== 'fan' &&
              <ListItem selected={selectedIndex === 0} classes={{ selected: classes.listItemSelected }}
                button component="a" onClick={handleSelectedIndex(0)}>
                <ListItemIcon><People /></ListItemIcon><ListItemText primary={'Personas'} />
              </ListItem>
            }
            <ListItem selected={selectedIndex === 3} classes={{ selected: classes.listItemSelected }}
              button component="a" onClick={handleSelectedIndex(3)}>
              <ListItemIcon><Eco /></ListItemIcon><ListItemText primary={'Ambientes de fauna'} />
            </ListItem>
            {!isPersonalAccount &&
              <ListItem selected={selectedIndex === 1} classes={{ selected: classes.listItemSelected }}
                button component="a" onClick={handleSelectedIndex(1)}>
                <ListItemIcon><Business /></ListItemIcon><ListItemText primary={'Ajustes'} />
              </ListItem>
            }
            <Divider />
          </AccessControl>
          {lastProjectsViewed.length > 0 && <>
            <ListSubheader color="primary">Últimos proyectos</ListSubheader>
            { lastProjectsViewed.map((lpv, idx) =>
              <ListItem button component="a" key={idx} dense onClick={() => goToProject(lpv)}>
                <ListItemText primary={lpv.projectName} disableTypography className={classes.longText}/>
              </ListItem>)
            }
            <Divider />
          </>}
          <AccessControl action="campaigns-page:visit">
            {lastCampaignsViewed.length > 0 && <ListSubheader color="primary">Últimas campañas</ListSubheader>}
            {lastCampaignsViewed.map((lcv, idx) =>
              <ListItem button component="a" key={idx} dense>
                <ListItemText title={lcv.campaignName} disableTypography className={classes.longText}
                  primary={lcv.campaignName} onClick={() => goToCampaign(lcv)}/>
              </ListItem>)
            }
          </AccessControl>
        </List>
      </Box>
      {companies.length > 1 &&
        <CompanySelectDialog
          companies={companies}
          selectedCompany={selectedCompany}
          actions={{ handleCloseCompaniesDialog }}
          open={openCompaniesDialog}
        />
      }
    </DrawerWrapper>
  );
};

CompanyDrawer.propTypes = {
  shouldUpdate: PropTypes.object,
  stopUpdate: PropTypes.func,
};


export { CompanyDrawer };

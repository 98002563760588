import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Box, Divider, List, ListSubheader, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CreditCard, DataUsage, Link } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import urlJoin from 'url-join';

import { WebContext } from 'src/scenes/web-context';
import { DrawerWrapper, WorkInSection } from 'src/components';
import { CashMultiple } from 'src/utils/mdIcons';
import { usersApi, companiesApi } from 'src/services';
import { CompanySelectDialog } from 'src/components/header/CompanySelectDialog';
import checkers from 'src/utils/checkers';
import { getIsNviroOrCswEmployee } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: 'transparent !important',
    border: `1px solid ${theme.palette.common.gray}`,
    height: '32px',
    width: '32px',
    marginRight: theme.spacing(1),
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.common.white,
  },
  listItem: {
    borderRadius: '6px',
  },
  list: {
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: '36px',
  },
  sectionHeader: {
    lineHeight: 0,
  },
  chip: {
    fontWeight: 'bold',
    width: '100%',
  },
  chipLabel: {
    flexGrow: 1,
  },
  workIn: {
    lineHeight: '1.2',
  },
}));

const itemList = [
  // {
  //   Icon: MonitorDashboard,
  //   label: 'Perfil de empresa',
  //   endPath: 'profile',
  //   isSubscriptionRequired: true,
  //   isRequiredToBeAnEmployeeOfCswOrNviro: true,
  // },
  {
    Icon: CreditCard,
    label: 'Suscripción',
    endPath: 'subscription',
    isActiveSubRequired: false,
    isRequiredToBeAnEmployeeOfCswOrNviro: false,
    subPaths: [ 'plans' ],
    sectionName: 'Plan y facturación',
  },
  {
    Icon: DataUsage,
    label: 'Uso de la plataforma',
    endPath: 'usage',
    isActiveSubRequired: true,
    isRequiredToBeAnEmployeeOfCswOrNviro: false,
  },
  {
    Icon: CashMultiple,
    label: 'Historial de pagos',
    endPath: 'payment-history',
    isActiveSubRequired: false,
    isRequiredToBeAnEmployeeOfCswOrNviro: false,
  },
  {
    Icon: Link,
    label: 'Vínculos con Monitor',
    endPath: 'linked-apps',
    subPaths: [ 'allowed-campaigns' ],
    isActiveSubRequired: true,
    isRequiredToBeAnEmployeeOfCswOrNviro: true,
    sectionName: 'Vínculos con otras apps',
  },
];

const getIndex = (currentPathname, filteredItemList) => filteredItemList.findIndex(item => {
  const lastString = currentPathname.split('/').pop();
  return item.endPath === lastString || item.subPaths?.includes(lastString);
});

const CompanySettingsDrawer = ({ company, changeCompany }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [ companies, setCompanies ] = useState({ companies: [] });

  const context = useContext(WebContext);
  const { currentUser: { email } } = context;

  const isNviroOrCswEmployee = getIsNviroOrCswEmployee(email);
  const filteredItemList = itemList.filter(item => !item.isRequiredToBeAnEmployeeOfCswOrNviro || isNviroOrCswEmployee);

  const [ selectedIndex, setSelectedIndex ] = useState(getIndex(pathname, filteredItemList));

  const [ openCompaniesDialog, setOpenDialog ] = useState(false);

  const baseUrl = `/web/company`;

  const handleSelectedIndex = index => () => {
    history.push(urlJoin(baseUrl, itemList[index].endPath));
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(getIndex(pathname, filteredItemList));
  }, [ pathname, filteredItemList ]);

  useEffect(() => {
    const fetchData = async () => {
      const companies = await companiesApi.getSelectableCompanies();
      setCompanies(companies);
    };
    fetchData();
  }, []);

  const commonListItemProps = { button: true, component: 'a', classes: { root: classes.listItem, selected: classes.listItemSelected } };

  const handleCloseCompaniesDialog = async selectedCompany => {
    setOpenDialog(false);
    if (selectedCompany.id !== company.id) {
      const role = await companiesApi.changeCompany(selectedCompany.id);
      changeCompany({ ...selectedCompany, ...role });
      const rolePermissions = await usersApi.getPermissionsByRole();
      context.changeRolePermissions(rolePermissions);
      history.push(`/web`);
    }
  };

  const isActiveOrUnpaidSub = checkers.isActiveOrUnpaidSub(company?.nviroPayCache?.subscriptionStatus);

  return (
    <DrawerWrapper>
      <Box mt={12}>
        <WorkInSection selectedCompany={company} actions={{ setOpenDialog }} />
        <List className={classes.list}>
          {filteredItemList.map(({ Icon, label, isActiveSubRequired, sectionName }, itemIndex) => (
            (isActiveOrUnpaidSub || !isActiveSubRequired) &&
              <Fragment key={itemIndex}>
                {sectionName &&
                  <>
                    <Box {...(itemIndex === 0 ? { pb: 2 } : { py: 2 })} mx={-1}>
                      <Divider />
                    </Box>
                    <Box py={2}>
                      <ListSubheader className={classes.sectionHeader} color="primary">
                        {sectionName}
                      </ListSubheader>
                    </Box>
                  </>
                }
                <ListItem
                  selected={selectedIndex === itemIndex}
                  onClick={handleSelectedIndex(itemIndex)} {...commonListItemProps}
                >
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
              </Fragment>
          ))}
        </List>
      </Box>
      {companies.length > 1 &&
        <CompanySelectDialog
          companies={companies}
          selectedCompany={company}
          actions={{ handleCloseCompaniesDialog }}
          open={openCompaniesDialog}
        />
      }
    </DrawerWrapper>
  );
};

CompanySettingsDrawer.propTypes = {
  company: PropTypes.object,
  changeCompany: PropTypes.func,
};


export { CompanySettingsDrawer };
import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import { WebContext } from 'src/scenes/web-context';
import { ExpiredDrawer, ContentContainer } from 'src/components';
import { companiesApi } from 'src/services';
import { Alert } from '@material-ui/lab';


const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const ExpiredContainer = () => {
  const classes = useStyles();
  const [ companies, setCompanies ] = useState([]);
  const history = useHistory();

  const context = useContext(WebContext);

  const { selectedCompany } = context;

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const companies = await companiesApi.getSelectableCompanies();
      if (isMounted) {
        setCompanies(companies);
      }
    };
    fetchData();
    return () => isMounted = false;
  }, []);

  return (
    <>
      <ExpiredDrawer
        company={selectedCompany}
        companies={companies}
        changeCompany={context.changeCompany}
        changeRolePermissions={context.changeRolePermissions}
        goToWeb={() => history.push(`/web`)}
      />
      <Box py={2} className={classes.mainContainer}>
        <Grid container>
          <ContentContainer>
            <Alert variant="filled" severity="error">
              La empresa a la que estás accediendo tiene su suscripción suspendida, para poder seguir utilizando la plataforma de
              Nviro Capture es necesario que se contrate alguna de las suscripciones disponibles.
            </Alert>
          </ContentContainer>
        </Grid>
      </Box>
    </>
  );
};


export {
  ExpiredContainer,
};